"use strict";

document.addEventListener('DOMContentLoaded', function () {

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hamburger, aside and menu animation
    // *
    // *

    // Define a function to handle the menu behavior
    function toggleMenu() {
        // Get the hamburger, menu, and menu close button and list items
        const hamburger = document.querySelector(".js-menu-hamburger");
        const aside = document.querySelector(".js-menu");
        const menuclose = document.querySelector(".js-menu-close");
        const menuItems = document.querySelectorAll(".js-menu-list li");

        // Define a function to animate the menu items forward
        function animateMenuItemsForward() {
            menuItems.forEach(function (item, index) {
                setTimeout(function () {
                    item.classList.add("show");
                }, index * 100);
            });
        }

        // Define a function to animate the menu items backward
        function animateMenuItemsBackward() {
            for (let i = menuItems.length - 1; i >= 0; i--) {
                setTimeout(function () {
                    menuItems[i].classList.remove("show");
                }, (menuItems.length - i - 1) * 100);
            }
        }

        // Define a function to toggle the menu state
        function toggleMenuState() {
            hamburger.classList.toggle("open");
            aside.classList.toggle("open");

            if (document.body.style.overflow === "hidden") {
                document.body.style.overflow = "auto";
            } else {
                document.body.style.overflow = "hidden";
            }
        }

        // Toggle the menu when the hamburger button is clicked
        if (hamburger !== null) {
            hamburger.onclick = function (e) {
                toggleMenuState();

                setTimeout(function () {
                    animateMenuItemsForward();
                }, 300);
            };
        }

        // Toggle the menu when the menu close button is clicked
        if (menuclose !== null) {
            menuclose.onclick = function (e) {
                animateMenuItemsBackward();

                setTimeout(function () {
                    toggleMenuState();
                }, menuItems.length * 100);
            };
        }

        // Toggle the menu when the 'Esc' key is pressed
        document.addEventListener("keydown", function (event) {
            if (event.key === "Escape") {
                event.preventDefault();
                animateMenuItemsBackward();

                setTimeout(function () {
                    toggleMenuState();
                }, menuItems.length * 100);
            }
        });

        // Toggle the menu when the user clicks outside of the menu
        document.onclick = function (e) {
            if (e.target.classList.contains("js-menu-close")) {
                toggleMenuState();
            }
        };
    }
    // Call the toggleMenu function to initialize the menu behavior
    toggleMenu();



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * header and sidebar
    // * change header background on scroll
    // *

    // Get the header and sidebar
    const header = document.querySelector(".js-header");
    const sidebar = document.querySelector(".js-sidebar");

    // Add a scroll event listener to the document to handle the header background and sidebar color
    document.addEventListener('scroll', function (e) {
        if (window.scrollY > 100) {
            header.classList.add('scroll');
            sidebar.classList.add('scroll');
        } else {
            header.classList.remove('scroll');
            sidebar.classList.remove('scroll');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * splide heroSlider
    // *
    // *

    // Select the heroSlider
    const heroSlider = document.getElementsByClassName('js-hero-slider');

    // Check if heroSlider were found
    if (heroSlider !== null) {

        // Loop through each element with class "js-hero-slider"
        for (let i = 0; i < heroSlider.length; i++) {
            new Splide(heroSlider[i], {
                type: 'fade',
                pauseOnHover: true,
                rewind: true,
                lazyLoad: 'nearby'
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * splide imageSlider
    // *
    // *

    // Select the imageSlider
    const imageSlider = document.getElementsByClassName('js-image-slider');

    // Check if imageSlider were found
    if (imageSlider !== null) {

        // Loop through each element with class "js-image-slider"
        for (let i = 0; i < imageSlider.length; i++) {
            new Splide(imageSlider[i], {
                type: 'fade',
                pauseOnHover: true,
                rewind: true,
                lazyLoad: 'nearby'
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * splide textSlider
    // *
    // *

    // Select the textSlider
    const textSlider = document.getElementsByClassName('js-text-slider');

    // Check if imageSlider were found
    if (textSlider !== null) {

        // Loop through each element with class "js-text-slider"
        for (let i = 0; i < textSlider.length; i++) {
            new Splide(textSlider[i], {
                type: 'slide',
                rewind: true,
                arrows: true,
                pagination: true,
                lazyLoad: 'nearby',
                perPage: 1,
                perMove: 1,
                gap: '3rem',
                breakpoints: {
                    768: {
                        perPage: 1,
                    }
                }
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * splide instagram
    // *
    // *

    // Select the instagramSlider
    const instagramSlider = document.querySelectorAll(".js-instagram-slider");

    // Check if imageSlider were found
    if (instagramSlider !== null) {
        for (let i = 0; i < instagramSlider.length; i++) {
            // splide init
            const splide = new Splide(instagramSlider[i], {
                type: 'loop',
                rewind: true,
                autoplay: true,
                arrows: true,
                pagination: false,
                lazyLoad: 'nearby',
                perPage: 3,
                perMove: 1,
                speed: 1000,
                interval: 2000,
                gap: '6rem',
                padding: {
                    left: 0,
                    right: 0
                },
                breakpoints: {
                    768: {
                        perMove: 1,
                        perPage: 2,
                    },
                    576: {
                        perMove: 1,
                        perPage: 1,
                        padding: '0'
                    },
                }
            }).mount();
        }
    };


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * splide instagram
    // *
    // *

    // Select the instagramSlider
    const quoteSlider = document.querySelectorAll(".js-quoteslider");

    // Check if imageSlider were found
    if (quoteSlider.length) {
        quoteSlider.forEach(function (slider, index) {
            // splide init
            const splide = new Splide(slider, {
                type: 'loop',
                rewind: true,
                autoplay: true,
                arrows: true,
                pagination: false,
                lazyLoad: 'nearby',
                perPage: 3,
                perMove: 1,
                speed: 1000,
                interval: 2000,
                gap: '6rem',
                padding: {
                    left: 0,
                    right: 0
                },
                breakpoints: {
                    768: {
                        perMove: 1,
                        perPage: 2,
                    },
                    576: {
                        perMove: 1,
                        perPage: 1,
                        padding: '0'
                    },
                }
            }).mount();
        });
    };

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * splide blogSlider
    // *
    // *

    // Select the heroSlider
    const blogSlider = document.getElementsByClassName('js-blog-slider');

    // Check if heroSlider were found
    if (blogSlider !== null) {

        // Loop through each element with class "js-blog-slider"
        for (let i = 0; i < blogSlider.length; i++) {
            new Splide(blogSlider[i], {
                type: 'fade',
                pauseOnHover: true,
                rewind: true,
                lazyLoad: 'nearby',
                arrows: true,
                pagination: false,
                autoHeight: true,
            }).mount();
        }
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollTo
    // *
    // *

    // Select all elements with the class "js-scrollTo" and store them in a variable called anchors
    const anchors = document.querySelectorAll(".js-scrollTo");

    // Iterate over each anchor and add a click event listener to it, calling the function clickHandler when clicked
    for (const anchor of anchors) {
        anchor.addEventListener("click", clickHandler);
    }

    // Function that is called when an anchor is clicked
    function clickHandler(e) {
        e.preventDefault();

        // Extract the ID of the target element from the anchor's href attribute and create a new ID by prepending "my" to it
        let href = this.getAttribute("href").split("#");
        let newAnchor = "#my" + href[1]

        // Get the offsetTop of the target element (i.e. how far it is from the top of the page)
        let offsetTop = document.querySelector(newAnchor).offsetTop;

        // Scroll to the target element using smooth scrolling behavior
        scroll({
            top: offsetTop,
            behavior: "smooth"
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hash link jump to content
    // *
    // *

    // Listen for the window's load event
    window.addEventListener('load', function () {
        // Retrieve the hash value from the URL
        let getHash = window.location.hash;
        // Check if an element with the corresponding ID exists on the page
        let elementExists = document.getElementById("my" + getHash.slice(1));

        // If the hash value and matching element exist
        if (getHash !== null && elementExists !== null) {
            // Construct a selector for the element using the hash value
            let name = "#my" + getHash.slice(1);
            // Calculate the element's offset from the top of the page
            let offsetTop = document.querySelector(name).offsetTop;

            // Use the `scroll() behavior` method to smoothly scroll to the element
            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
        }
    }, false);



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollToTop
    // *
    // *

    const scrolltotop = document.querySelector(".js-scrolltop");

    if (scrolltotop !== null) {
        // show and hide scrollToTop btn on scroll
        document.addEventListener('scroll', function (e) {
            if (window.scrollY > 300) {
                scrolltotop.classList.add("show");
            } else {
                scrolltotop.classList.remove("show");
            }
        });

        // onclick scroll to top
        scrolltotop.onclick = function (e) {
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

        // onkeydown scroll to top or bottom
        scrolltotop.addEventListener("keydown", function (event) {
            if (event.keyCode === 13 || event.keyCode === 32) {
                event.preventDefault();

                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    // * anchor nav scrollTo
    // *
    // *

    // get browser width
    let browserWidth = window.innerWidth;
    let anchorBox = document.querySelector('.js-anchor-nav');
    let anchorBoxSticky = document.querySelector('.js-anchor-sticky');

    // check if anchor modul is set to sticky
    if (anchorBoxSticky !== null) {
        window.addEventListener('scroll', function (e) {
            // get pos from anchorbox
            let pos = Math.round(anchorBox.getBoundingClientRect().y);

            if (pos <= 0) {
                anchorBox.classList.add("stick");
            } else {
                anchorBox.classList.remove("stick");
            }
        });
    }

    // check if anchor modul is set
    if (anchorBox !== null) {
        // needed for sticky anchor to calculate offsetTop
        anchorBoxHeight = anchorBox.offsetHeight;

        if (browserWidth > 1280) {
            anchorBoxHeight = anchorBoxHeight;
        } else {
            anchorBoxHeight = 0;
        }

        const anchors = document.querySelectorAll(".js-scrollTo");

        for (const anchor of anchors) {
            anchor.addEventListener("click", clickHandler);
        }

        function clickHandler(e) {
            e.preventDefault();

            let href = this.getAttribute("href").split("#");
            let newAnchor = "#my" + href[1]
            let offsetTop = document.querySelector(newAnchor).offsetTop - anchorBoxHeight;

            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * modal window
    // *
    // *

    let modalWindow = document.querySelector(".js-modal");
    let modalOpen = document.querySelector(".js-modal-btn-open");
    let modalClose = document.querySelector(".js-modal-btn-close");

    /* onclick show modal window */
    if (modalOpen !== null) {
        modalOpen.onclick = function () {
            modalWindow.style.display = "block";
            modalClose.focus();
        }
    }

    /* close modal window */
    if (modalClose !== null) {
        modalClose.onclick = function () {
            modalWindow.style.display = "none";
        }
    }

    /* clicks anywhere outside close modal window */
    window.onclick = function (event) {
        if (event.target == modalWindow) {
            modalWindow.style.display = "none";
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion open every tab
    // *
    // *

    const title = document.querySelectorAll(".js-accordion-title");

    if (title !== null) {
        for (var i = 0; i < title.length; i++) {
            title[i].onclick = function (e) {
                this.classList.toggle("open");
                this.nextElementSibling.classList.toggle("open");

                let expanded = this.getAttribute('aria-expanded');

                if (expanded == 'false') {
                    this.setAttribute('aria-expanded', 'true')
                } else {
                    this.setAttribute('aria-expanded', 'false')
                }
            }
            title[i].addEventListener("keydown", function (event) {
                if (event.keyCode === 13 || event.keyCode === 32) {

                    event.preventDefault();

                    this.classList.toggle("open");
                    this.nextElementSibling.classList.toggle("open");

                    let expanded = this.getAttribute('aria-expanded');

                    if (expanded == 'false') {
                        this.setAttribute('aria-expanded', 'true')
                    } else {
                        this.setAttribute('aria-expanded', 'false')
                    }
                }
            });
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * open external links in new tab
    // *
    // *

    function externalLinks() {
        for (let links = document.getElementsByTagName("a"), number = 0; number < links.length; number++) {
            let currentLink = links[number];

            // check if link has same hostname. if not add target blank.
            currentLink.getAttribute("href") && currentLink.hostname !== location.hostname && (currentLink.target = "_blank")
        }
    };

    externalLinks();




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * simpleParallax
    // *
    // *

    let parallaxImages = document.getElementsByClassName('js-parallax');
    new simpleParallax(parallaxImages, {
        delay: 1,
        scale: 1.25
    });

    let parallaxEmblem = document.getElementsByClassName('js-emblem-parallax');
    new simpleParallax(parallaxEmblem, {
        delay: 1,
        scale: 2
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * aos
    // *
    // *

    AOS.init({
        offset: 0,
        duration: 700,
        delay: 100,
        disable: 'mobile',
        easing: 'ease-out-back'
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * ImageCompare
    // *
    // *

    const viewers = document.querySelectorAll(".image-compare");
    const options = {
        controlColor: "#ffffff",
        controlShadow: false,
        addCircle: true,
        addCircleBlur: true
    };

    viewers.forEach((element) => {
        let view = new ImageCompare(element, options).mount();
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Tab Box
    // *
    // *

    // Get Buttons, Contents and Teaser image
    const tabButtons = document.querySelectorAll(".js-tablinks");
    const tabContents = document.querySelectorAll(".js-tabcontent");
    const tabTeaserImage = document.querySelector(".js-tabteaser-image");

    // Add event listener to each tab button
    tabButtons.forEach((button) => {
        button.addEventListener("click", () => {
            // Get the ID of the content to show
            const tabId = button.getAttribute("id").replace("-button", "");

            // Hide all tab contents
            tabContents.forEach((content) => {
                content.classList.remove("show");
            });

            // Get the tab content to show and display it
            const tabContentToShow = document.getElementById(tabId);
            tabContentToShow.classList.add("show");

            // Remove the active class from all tab buttons
            tabButtons.forEach((button) => {
                button.classList.remove("active");
            });

            // Add the active class to the clicked tab button
            button.classList.add("active");

            // Set image src attribute to the value of the data-image attribute of the clicked tab button
            const tabTeaserImagePath = button.getAttribute("data-image");
            if (tabTeaserImagePath) {
                tabTeaserImage.setAttribute("src", tabTeaserImagePath);
            }
        });
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Integra animation on footer
    // *
    // *

    window.addEventListener('load', function () {
        var integraImage = document.querySelector('.js-integra');
        var footer = document.querySelector('footer');
        var footerTop = footer.offsetTop;

        window.addEventListener('scroll', function () {
            if (window.pageYOffset + window.innerHeight >= footerTop) {
                integraImage.classList.add('show');
            } else {
                integraImage.classList.remove('show');
            }
        });
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * video - play and stop video in viewport
    // *
    // *

    const videos = document.querySelectorAll(".js-video");
    // Create a new IntersectionObserver
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            // If the video is intersecting the viewport, play it
            if (entry.isIntersecting) {
                entry.target.play();
            } else {
                // Otherwise, pause it
                entry.target.pause();
            }
        });
    });

    // Loop through all videos and observe them
    videos.forEach((video) => {
        // Check if the video element has the class "js-video"
        if (video.classList.contains("js-video")) {
            observer.observe(video);
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add product to shopping cart
    // *
    // *

    // Get the input element to manipulate
    const input = document.querySelector(".js-inputnumber-input");

    // Get all buttons with class "js-inputnumber-button"
    const buttons = document.querySelectorAll(".js-inputnumber-button");

    // Loop through all buttons and add a click event listener
    for (let i = 0; i < buttons.length; i++) {
        buttons[i].addEventListener("click", function () {

            // Get the value of the input element and convert it to a number
            let currentValue = parseInt(input.value);

            // Check which button was clicked to increment or decrement the value accordingly
            if (this.dataset.action === "plus") {
                currentValue++;
            } else if (this.dataset.action === "minus") {
                currentValue--;
            }

            // Ensure that the new value of the input element is within the range specified by the "min" and "max" attributes
            if (currentValue < parseInt(input.getAttribute("min"))) {
                currentValue = parseInt(input.getAttribute("min"));
            } else if (currentValue > parseInt(input.getAttribute("max"))) {
                currentValue = parseInt(input.getAttribute("max"));
            }

            // Set the new value of the input element
            input.value = currentValue;
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * load more blog
    // *
    // *
    const loadMoreButton = document.querySelector(".js-load-more-blog")
    if (loadMoreButton) {
        loadMoreButton.addEventListener('click', function (e) {
            e.preventDefault();
            document.querySelector(".js-load-more-bog-tpl").classList.add("active");
            this.classList.add("hide");
        });

        const numberOfChildren = document.querySelector(".js-count-blog-items").childElementCount;
        if (numberOfChildren < 4) {
            loadMoreButton.classList.add("hide")
        }
    }


});
